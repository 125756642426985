import React from "react";
import Icon from '@mdi/react';
import { mdiRocket } from '@mdi/js';
import "../styles/Projects.css";

function Projects() {
    return(
    <div className="medium-padding-top medium-padding-bottom " id="projects">
        <h3 className="alt small-margin-top small-padding-bottom">Project Section </h3>
        <p>Coming Soon</p>
    </div>
    );
}


export default Projects;